// Essential for all components
import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import {withRouter} from 'react-router-dom';
// Redux
import {connect} from 'react-redux';
// Material UI
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
// Include
import {setBreadcrumb} from "../../Redux/Action/breadcrumbAction";
import moment from "moment";
import {
    getCurrentStateOfPage,
    getPreviousPage,
    getValAfterSlash,
    handleSpecialFieldName
} from "../../utils/PaginationUtils";
import queryString from "query-string";
import {setPaginator} from "../../Redux/Action/paginatorAction";
import BaseListPage from "../../components/101BaseListPage/BaseListPage";
import { CustomDatePicker } from "../../components/103FormikCustom/DatePickerField"
import {apiReport} from "../../Api/_ApiReport";
import {downLoadFile} from "../../utils/Utility";
import {addMessage} from "../../Redux/Action/messageAction";


// Define column names
const rows = [
    { id: 'name', label: 'Report', numeric: false, disablePadding: false, actionLink: true, actionParam: 'endpoint' },
];

const rowsPerPageDefault = 15;
const orderByDefault = 'sequence';
const orderDefault = 'asc';

class ReportList extends Component {

    state = {
        data: [],
        //pagination
        amountOfData: '0',
        currentPage: 0,
        rowsPerPage: rowsPerPageDefault,
        orderBy: orderByDefault,
        order: orderDefault,
        startDate: new Date(moment().subtract(7, 'day').valueOf()),
        endDate: new Date(),
    };

    componentDidMount() {
        const values = queryString.parse(this.props.location.search)
        let previousPage = values.previous ? getPreviousPage(this.props.currentPaginator, 'report') : null;
        const {currentPage, rowsPerPage, orderBy, order} = getCurrentStateOfPage(previousPage, rowsPerPageDefault, orderByDefault, orderDefault)
        this.setState({
            currentPage: currentPage,
            rowsPerPage: rowsPerPage,
            orderBy: orderBy,
            order: order
        }, () => {
            this._getDataDynamic(currentPage * rowsPerPage, rowsPerPage, orderBy, order, true);
        })
    }

    _getDataDynamic = (skipAmount, rowsPerPage, orderBy, order, isResetNavAndDataAmount, searchSubject) => {
        const { platform, i18n } = this.props;
        orderBy = handleSpecialFieldName(orderBy);
        const params =
            {
                '$orderby': `${orderBy} ${order}`,
                '$top': rowsPerPage,
                '$skip': skipAmount,
                '$select': 'report_id,sequence,name,endpoint,lastmoddate'
            };
        console.log('param: ', params)
        apiReport.getReports(platform, params).then(rs => {
            if (rs && rs.status === 200) {
                if (isResetNavAndDataAmount) {
                    const dataSum = getValAfterSlash(rs.headers['content-range']);
                    this.setState({amountOfData: dataSum});
                }
                this.setState({
                    data: this.prepareData(rs.data),
                });
            } else {
                console.log("eCb : ", rs);
            }
        })

        this.props.setBreadcrumbP(
            {
                breadcrumbData: [
                    {
                        title: `Report ${this.props.platform}`,
                        link: '/' + i18n.language + '/report'
                    }
                ]
            }
        );
    }

    prepareData = data => {
        return (data && data.length > 0) ? (
            data.map(item => ({
                ...item,
                id: item.report_id,
                update_dated: moment(item.lastmoddate).format('YYYY-MM-DD HH:mm:ss'),
            }))
        ) : [];
    };

    clickToFunction = (endpoint) => {
        const { platform } = this.props;
        const { startDate, endDate } = this.state;
        const reportParams = {
            tenant: localStorage.getItem('asTenantId'),
            ...(startDate ? { start_date: moment(startDate).startOf('day').valueOf() } : {}),
            ...(endDate ? { end_date: moment(endDate).add(1, 'day').startOf('day').valueOf() } : {}),
        }
        apiReport.downloadReport(platform, endpoint, reportParams, null).then(res => {
            if (res && res.status === 200) {
                const report = this.state.data.find(item => item.endpoint === endpoint);
                const reportName = report ? report.name.replaceAll(' ', '_') : 'report';
                const fileName = `${reportName}_${moment().format('YYYYMMDD')}.xlsx`;
                downLoadFile(res.data, fileName);
            } else {
                const msg = res && res.data.error ? res.data.error : 'Cannot download report.';
                const msgDsp = {
                    messageSnackbar: msg,
                    variantSnackbar: 'error',
                    key: new Date().getTime(),
                };
                this.props.addMessageP(msgDsp);
            }
        })
    }

    render() {
        const { data, amountOfData, currentPage, rowsPerPage, orderBy, order, startDate, endDate } = this.state;
        return (
            <div>
                <Grid container alignItems="center" spacing={2} style={{ padding: 30, paddingBottom: 0 }}>
                    <Hidden smDown>
                        <Grid item container xs={12} md={6} justify="flex-end">
                            Date Range:
                        </Grid>
                    </Hidden>
                    <Hidden mdUp>
                        <Grid item container xs={12} md={6} justify="flex-start">
                            Date Range:
                        </Grid>
                    </Hidden>
                    <Grid item xs={12} sm={6} md={3}>
                        From:
                        <CustomDatePicker
                            value={startDate}
                            maxDate={endDate ? new Date(endDate) : null}
                            onChange={(v) => this.setState({ startDate: v })}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        To:
                        <CustomDatePicker
                            value={endDate}
                            minDate={startDate ? new Date(startDate) : null}
                            onChange={(v) => this.setState({ endDate: v })}
                        />
                    </Grid>
                </Grid>
                {data && (
                    <BaseListPage
                        pageId={'report'}
                        mainClass={'report-page full-width'}
                        primaryKey='report_id'
                        displaySelectBox={false}
                        displaySearchBar={false}
                        displayActionBar={false}

                        getDataDynamic={this._getDataDynamic}
                        clickToFunction={this.clickToFunction}
                        currentPage={currentPage}
                        rows={rows}
                        data={data}
                        orderDefault={order}
                        orderByDefault={orderBy}
                        rowsPerPageDefault={rowsPerPage}
                        rowsPerPageOptions={[5, 10, 20]}
                        amountOfData={amountOfData}
                    />
                )}
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    currentPaginator: state.paginator.currentPaginator
});
const mapDispatchToProps = dispatch => ({
    setBreadcrumbP: data => dispatch(setBreadcrumb(data)),
    setPaginator: data => dispatch(setPaginator(data)),
    addMessageP: data => dispatch(addMessage(data)),
});
export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(withRouter(ReportList)));