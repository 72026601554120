import qs from 'querystringify';
import { api, getBaseUrl } from "./_ApiFactoryWithHeader";

export const apiReport = {
    getReports: (platform, params) => {
        return getBaseUrl(platform).then(baseUrl => {
            const url = `${baseUrl}/reports`;
            return api.get(url, params, null);
        });
    },
    downloadReport: (platform, id, params, option) => {
        return getBaseUrl(platform).then(baseUrl => {
            let url = `${baseUrl}/download_reports/${id}`;
            const query = qs.stringify(params);
            if (query.length > 0) {
              url += `?${query}`;
            }
            return api.getXlsxFile(url,{ responseType: 'blob', ...option });
        });
    },
};
