import i18n from 'i18next';
// import LanguageDetector from 'i18next-browser-languagedetector';
import {initReactI18next} from 'react-i18next';

import en_us_Common from "./en-us/Common.json";
import en_us_LoginRegister from "./en-us/LoginRegister.json";
import en_us_Upload from "./en-us/Upload.json";
import en_us_Sidebar from "./en-us/Sidebar.json";
import en_us_UserManagement from "./en-us/UserManagement.json";
import en_us_AuctionManagement from "./en-us/AuctionManagement.json";
import en_us_ClientManagement from "./en-us/ClientManagement.json";
import en_us_IndustryManagement from "./en-us/IndustryManagement.json";
import en_us_TenantManagement from "./en-us/TenantManagement.json";
import en_us_TenantMemberManagement from "./en-us/TenantMemberManagement.json";

import zh_hk_Common from "./zh-hk/Common.json";
import zh_hk_LoginRegister from "./zh-hk/LoginRegister.json";
import zh_hk_Upload from "./zh-hk/Upload.json";
import zh_hk_Sidebar from "./zh-hk/Sidebar.json";
import zh_hk_UserManagement from "./zh-hk/UserManagement.json";
import zh_hk_AuctionManagement from "./zh-hk/AuctionManagement.json";
import zh_hk_ClientManagement from "./zh-hk/ClientManagement.json";
import zh_hk_IndustryManagement from "./zh-hk/IndustryManagement.json";
import zh_hk_TenantManagement from "./zh-hk/TenantManagement.json";
import zh_hk_TenantMemberManagement from "./zh-hk/TenantMemberManagement.json";

i18n
    // load translation using xhr -> see /public/locales
    // learn more: https://github.com/i18next/i18next-xhr-backend
    // .use(Backend)
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    // .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        lng: 'en-US',
        fallbackLng: 'zh-HK',
        debug: false,

        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },

        ns: ['common', 'contact'],
        defaultNS: 'common',
        resources: {
            'en-US': {
                Common: en_us_Common,
                LoginRegister: en_us_LoginRegister,
                UserManagement: en_us_UserManagement,
                AuctionManagement: en_us_AuctionManagement,
                ClientManagement: en_us_ClientManagement,
                IndustryManagement: en_us_IndustryManagement,
                TenantManagement: en_us_TenantManagement,
                TenantMemberManagement: en_us_TenantMemberManagement,
                Upload: en_us_Upload,
                Sidebar: en_us_Sidebar,
            },
            'zh-HK': {
                Common: zh_hk_Common,
                LoginRegister: zh_hk_LoginRegister,
                UserManagement: zh_hk_UserManagement,
                AuctionManagement: zh_hk_AuctionManagement,
                ClientManagement: zh_hk_ClientManagement,
                IndustryManagement: zh_hk_IndustryManagement,
                TenantManagement: zh_hk_TenantManagement,
                TenantMemberManagement: zh_hk_TenantMemberManagement,
                Upload: zh_hk_Upload,
                Sidebar: zh_hk_Sidebar,
            }
        }
    });

export default i18n;
