// Essential for all components
import React, {Component} from 'react';
import {withTranslation} from "react-i18next";
import {withRouter} from "react-router-dom";

import Grid from "@material-ui/core/Grid";
import {Button} from "@material-ui/core";
import TextField from '@material-ui/core/TextField';

import {setBreadcrumb} from "../../Redux/Action/breadcrumbAction";
import {connect} from "react-redux";
import {apiUsers} from "../../Api/_ApiUsers";
import {addMessage} from "../../Redux/Action/messageAction";
import {apiClient} from "../../Api/_ApiClient";

class OneToOneMsg extends Component {

    state = {
        title: '',
        msgValue: '',
        clientDetail: null,
        loadedData: false
    };

    componentDidMount() {
        if (this.props.id) {
            this.getDetail();
        } else {
            const userItemBreadcrumb = [
                {
                    title: 'Send One-2-One Message to All',
                    link: null
                },
            ];
            this.props.setBreadcrumbP({breadcrumbData: userItemBreadcrumb});
            this.setState({loadedData: true})
        }
    }

    getDetail = () => {
        const {t} = this.props;

        let params = {}
        apiClient.getClientDetailByTenantMemberId(this.props.id, params).then(obj => {
            if (obj && obj.status === 200) {
                if (obj.data) {
                    const client = obj.data;
                    const userItemBreadcrumb = [
                        {
                            title: `${t('ClientManagement:breadcrumb')}`,
                            link: null
                        },
                        {
                            title: client.display_name,
                            link: null
                        },
                        {
                            title: t('ClientManagement:one2oneMsg'),
                            link: null
                        },
                    ];
                    this.props.setBreadcrumbP({breadcrumbData: userItemBreadcrumb});
                    this.setState({clientDetail: client, loadedData: true})
                }
            }
        })
    }

    back = () => {
        const {i18n} = this.props;
        this.props.history.push('/' + i18n.language + '/client-management?previous=true');
    }

    handleTitleChange = (event) => {
        this.setState({title: event.target.value});
    };

    handleChange = (event) => {
        this.setState({msgValue: event.target.value});
    };

    send = () => {
        const {i18n} = this.props;
        if (this.state.msgValue && this.state.title && this.state.loadedData) {
            let body = {
                tenant: localStorage.getItem('asTenantId'),
                "title": this.state.title,
                "content": this.state.msgValue,
            };
            if (this.props.id) {
                body['is_send_all'] = false;
                body['message_receivers'] = [
                    {
                        "user": this.state.clientDetail.user || this.state.clientDetail.email
                    }
                ];
            } else {
                body['is_send_all'] = true;
            }

            apiUsers.sendOneToOneMsg(this.props.id, body).then(obj => {
                if (obj.status === 201) {
                    const msgDsp = {
                        messageSnackbar: 'The message is sent successfully',
                        variantSnackbar: 'success',
                        key: new Date().getTime(),
                    };
                    //Add msg
                    this.props.addMessageP(msgDsp);
                    this.props.history.push('/' + i18n.language + '/client-management?previous=true')
                } else {
                    const error = obj.data && obj.data.error ? obj.data.error : 'Send message failed';
                    //Add msg
                    this.props.addMessageP({
                        messageSnackbar: error,
                        variantSnackbar: 'error',
                        key: new Date().getTime(),
                    });
                }
            })
        }

    }

    render() {

        const {t} = this.props;
        const {title, msgValue} = this.state;

        return (
            <div>
                <div className="main__container flex-center-item">
                    <div className="full-width client-detail">
                        <Grid container direction="row" justify="center" alignItems="flex-start">
                            <Grid item xs={2} className="form-item">
                                Title
                            </Grid>
                            <Grid item xs={10} className="form-item">
                                <TextField
                                    className="full-width"
                                    value={title}
                                    onChange={this.handleTitleChange}
                                    variant="outlined"
                                    inputProps={{
                                        maxLength: 40,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={2} className="form-item">
                            </Grid>
                            <Grid item xs={10} className="form-item">
                                <Grid container xm={12} alignItems="center">
                                    <Grid item xs={8} className="button-wrapper form-item">
                                        {t("Common:Form.validator.required")}
                                    </Grid>
                                    <Grid item xs={4} className="button-wrapper form-item flex-end-item">
                                        {title ? title.length : 0}/40
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={2} className="form-item pt10">
                                {t("ClientManagement:msgContent")}
                            </Grid>
                            <Grid item xs={10} className="form-item pt10">
                                <TextField
                                    multiline
                                    className="full-width"
                                    rows={4}
                                    value={msgValue}
                                    onChange={this.handleChange}
                                    variant="outlined"
                                    inputProps={{
                                        maxLength: 178,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={2} className="form-item">
                            </Grid>
                            <Grid item xs={10} className="form-item">
                                <Grid container xm={12} alignItems="center">
                                    <Grid item xs={8} className="button-wrapper form-item">
                                        {t("Common:Form.validator.required")}
                                    </Grid>
                                    <Grid item xs={4} className="button-wrapper form-item flex-end-item">
                                        {msgValue ? msgValue.length : 0}/178
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} className="button-wrapper form-item pt20">
                                <Button type="button" className="primary-button" onClick={() => {
                                    this.send()
                                }}>Send</Button>
                                <Button type="button" className="back-button" onClick={() => {
                                    this.back()
                                }}>Back</Button>
                            </Grid>
                        </Grid>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    currentPaginator: state.paginator.currentPaginator
});
const mapDispatchToProps = dispatch => ({
    setBreadcrumbP: data => dispatch(setBreadcrumb(data)),
    addMessageP: data => dispatch(addMessage(data)),
});
export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(withRouter(OneToOneMsg)));
