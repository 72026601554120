// Essential for all components
import React, {Component} from 'react';
import {withTranslation} from "react-i18next";
import {withRouter} from "react-router-dom";

import {connect} from "react-redux";
import {setBreadcrumb} from "../../Redux/Action/breadcrumbAction";
import {apiUsers} from "../../Api/_ApiUsers";
import Grid from "@material-ui/core/Grid";
import {Button} from "@material-ui/core";
import Snackbar from "@material-ui/core/Snackbar";
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import {Field, Form, Formik} from "formik";
import * as Yup from "yup";

import ErrorMessage from "../../components/100Include/ErrorMessage";
import {getRoleDisplay} from "../../utils/Utility";
import moment from "moment";
import {MySnackbarContentWrapper} from "../../components/102MaterialDesign/MySnackbarContentWrapper";
import {getUserInfo} from "../../Redux/Action/authAction";
import {CacheService} from "../../utils/CacheService";
import {apiAuth} from "../../Api/ApiAuth";

class Profile extends Component {

    state = {
        roles: [],
        isEdit: false,
        user: {
            username: '',
            display_name: '',
            email: '',
            profile_image: null,
            password: '',
            re_password: ''
        },
        files: [],
        fileUpload: null,
        openSnackbar: false,
        messageSnackbar: '',
        variantSnackbar: '',
        openChangePwdDialog: false,
        roleValidateMsg: null
    };

    constructor(props) {
        super(props);
        this.myFiles = React.createRef();
    }

    componentDidMount() {
        console.log(this.props.auth)
        if (this.props.auth && this.props.auth.userInfo) {
            this.setState({isEdit: true})
            this.getUserDetail();
        }
    }

    getUserDetail = () => {
        const {t, i18n} = this.props;
        let params = {
            // $expand: "profile_image"
        }
        apiUsers.getUserDetail(this.props.auth.userInfo.username, params).then(obj => {
            if (obj && obj.status === 200) {
                if (obj.data) {
                    const userDetail = obj.data;
                    this.props.setBreadcrumbP({
                        breadcrumbData: [{
                            title: t('UserManagement:profile'),
                            link: '/' + i18n.language + '/profile'
                        }]
                    });
                    this.setState({
                        user: this.prepareData(userDetail)
                    });
                }
            } else {
                this.props.history.push('/' + i18n.language + '/login');
            }
        })
    }

    prepareData = data => {
        const item = {...data};
        item.update_date = item.lastmoddate ? moment(item.lastmoddate).format('YYYY-MM-DD HH:mm:ss') : '';
        item.role_display = getRoleDisplay(item.role, item.access_rights);
        item.password = 'password'
        item.re_password = 'password'
        return item;
    };
    // BUTTON FUNCTION

    handleCloseSnackbar = () => {
        this.setState({openSnackbar: false});
    };

    cancel = () => {
        this.props.history.goBack();
    }

    eCb = (obj) => {
        console.log("eCb : ", obj);
    }

    _updateUserAsync = (value) => {
        let regex = /^\s+|\s+$/g;
        let updateUserBody = {
            "display_name": value.display_name,
            "username": value.email.replace(regex, ''),
            "email": value.email.replace(regex, ''),
        }
        this.callAPIUpdateUser(this.props.auth.userInfo.username, updateUserBody);
    }

    callAPIUpdateUser = (email, body) => {
        apiUsers.updateUser(email, body).then(obj => {
            if (obj.status === 200) {
                this._getCurrentUserInformation();
                this.setState({
                    openSnackbar: true,
                    messageSnackbar: 'Update user successfully',
                    variantSnackbar: 'success'
                })
            } else {
                const error = obj.data && obj.data.error ? obj.data.error : 'Update user failed';
                this.setState({
                    openSnackbar: true,
                    messageSnackbar: error,
                    variantSnackbar: 'error'
                });
            }
        });
    }

    _getCurrentUserInformation = () => {
        const params = null;
        apiAuth.getCurrentUserInformation(params).then(obj => {
            if (obj) {
                this.props.getUserInfoP(obj.data);
                CacheService.saveProfileData(obj.data)
            }

        })
    }

    _submitUserForm = (value) => {
        if (this.state.isEdit) {
            this._updateUserAsync(value);
        }
    }

    handleChange = event => {
        this.setState({
            roles: event.target.value,
            roleValidateMsg: event.target.value.length === 0 ? 'Role is required' : null
        });
    };

    // CHANGE PASSWORD
    openChangePwd = () => {
        this.setState({openChangePwdDialog: true});
    };
    handleCloseChangePwd = () => {
        this.setState({openChangePwdDialog: false});
    };

    _submitChangePwdForm = (value) => {
        if (value.role === 1) {
            const body = {"new_password": value.new_password}
            apiUsers.changePassword(this.state.user.username, body).then(obj => {
                if (obj.status === 204) {
                    this.setState({
                        openSnackbar: true,
                        messageSnackbar: 'Change password successfully.',
                        variantSnackbar: 'success',
                        openChangePwdDialog: false
                    })
                } else {
                    const msg = obj && obj.data.error ? obj.data.error : 'Cannot change user password';
                    this.setState({
                        openSnackbar: true,
                        messageSnackbar: msg,
                        variantSnackbar: 'error'
                    })
                }
            });
        } else {
            const body = {
                "old": {
                    "password": value.old_password
                },
                "new": {
                    "password": value.new_password
                }
            };
            apiUsers.changePasswordUser(this.state.user.username, body).then(obj => {
                if (obj.status === 204) {
                    this.setState({
                        openSnackbar: true,
                        messageSnackbar: 'Change password successfully.',
                        variantSnackbar: 'success',
                        openChangePwdDialog: false
                    })
                } else {
                    const msg = obj && obj.data.error.endsWith("is not authorized.") ? 'Incorrect old password, please try again.' : 'Cannot change user password';
                    this.setState({
                        openSnackbar: true,
                        messageSnackbar: msg,
                        variantSnackbar: 'error'
                    })
                }
            });
        }
    }

    // FORM CONFIG
    formConfiguration = ({values, errors, touched, handleChange}) => {
        const {t} = this.props;
        return values && (
            <Form id="userForm" className="full-width">
                <Grid container xm={12} alignItems="center">
                    {this.state.MessageContent &&
                    <Grid item xs={12} className="ErrorMessage form-item">
                        <ErrorMessage
                            message={this.state.MessageContent}
                        />
                    </Grid>
                    }

                    <Grid container direction="row" justify="center" alignItems="flex-start">
                        <Grid item xs={2} className="form-item">
                            {t("UserManagement:userName")}
                        </Grid>
                        <Grid item xs={10} className="form-item">
                            <Grid item xs={12}>
                                <Field name="display_name" type="text" placeholder="" maxLength="100"/>
                            </Grid>
                            <Grid item xs={12}>
                                {errors.display_name && touched.display_name ?
                                    <ErrorMessage message={errors.display_name}/> : t("Common:Form.validator.required")}
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid container direction="row" justify="center" alignItems="flex-start">
                        <Grid item xs={2} className="form-item">
                            {t("UserManagement:email")}
                        </Grid>
                        <Grid item xs={10} className="form-item">
                            <Grid item xs={12}>
                                <Field name="email" type="text" placeholder="" maxLength="100"/>
                            </Grid>
                            <Grid item xs={12}>
                                {errors.email && touched.email ?
                                    <ErrorMessage message={errors.email}/> : t("Common:Form.validator.required")}
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid container direction="row" justify="center" alignItems="flex-start">
                        <Grid item xs={2} className="form-item">
                            {t("UserManagement:password")}
                        </Grid>
                        <Grid item xs={10} className="form-item">
                            <Grid item xs={12}>
                                <Button type="button" className="primary-button" onClick={this.openChangePwd}>Set
                                    Password</Button>
                            </Grid>
                            <Grid item xs={12}>
                            </Grid>
                        </Grid>
                    </Grid>

                    {values.isEdit &&
                    <Grid container direction="row" justify="center" alignItems="flex-start" className="pt10">
                        <Grid item xs={2} className="form-item">
                            {t("UserManagement:updatedDate")}
                        </Grid>
                        <Grid item xs={10} className="form-item">
                            {values.lastmoddate ? moment(values.lastmoddate).format('YYYY-MM-DD HH:mm:ss') : ''}

                        </Grid>
                    </Grid>
                    }
                    <Grid item xs={2} className="form-item">
                    </Grid>
                    <Grid item xs={12} className="button-wrapper form-item pt20">
                        <Button type="submit" className="primary-button">Save</Button>
                        <Button type="button" className="second-button" onClick={() => {
                            this.cancel()
                        }}>Cancel</Button>
                    </Grid>
                </Grid>
            </Form>
        )
    }

    formChangePwdConfiguration = ({values, errors, touched, handleChange}) => {
        const {t} = this.props;
        return values && (
            <Form id="changePwdForm" className="full-width">
                <Grid container xm={12} alignItems="center">
                    {this.state.ChangePwdMessageContent &&
                    <Grid item xs={12} className="ErrorMessage form-item">
                        <ErrorMessage
                            message={this.state.ChangePwdMessageContent}
                        />
                    </Grid>
                    }

                    {
                        values.role === 2 &&
                        <Grid container direction="row" justify="center" alignItems="flex-start">
                            <Grid item xs={3} className="form-item">
                                {t("UserManagement:currentPassword")}
                            </Grid>
                            <Grid item xs={9} className="form-item">
                                <Grid item xs={12}>
                                    <Field name="old_password" type="password" placeholder="" maxLength="100"/>
                                </Grid>
                                <Grid item xs={12}>
                                    {(errors.old_password && touched.old_password ? <ErrorMessage
                                        message={errors.old_password}/> : t("Common:Form.validator.required"))}
                                </Grid>
                            </Grid>
                        </Grid>
                    }
                    <Grid container direction="row" justify="center" alignItems="flex-start">
                        <Grid item xs={3} className="form-item">
                            {t("UserManagement:newPassword")}
                        </Grid>
                        <Grid item xs={9} className="form-item">
                            <Grid item xs={12}>
                                <Field name="new_password" type="password" placeholder="" maxLength="100"/>
                            </Grid>
                            <Grid item xs={12}>
                                {(errors.new_password && touched.new_password ? <ErrorMessage
                                    message={errors.new_password}/> : t("Common:Form.validator.required"))}
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid container direction="row" justify="center" alignItems="flex-start">
                        <Grid item xs={3} className="form-item">
                            {t("UserManagement:rePassword")}
                        </Grid>
                        <Grid item xs={9} className="form-item">
                            <Grid item xs={12}>
                                <Field name="re_new_password" type="password" placeholder="" maxLength="100"/>
                            </Grid>
                            <Grid item xs={12}>
                                {errors.re_new_password && touched.re_new_password ? <ErrorMessage
                                    message={errors.re_new_password}/> : t("Common:Form.validator.required")}
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={2} className="form-item">
                    </Grid>
                    <Grid item xs={12} className="button-wrapper form-item pt20">
                        <Button type="submit" className="primary-button">Save</Button>
                        <Button type="button" className="second-button" onClick={() => {
                            this.handleCloseChangePwd()
                        }}>Cancel</Button>
                    </Grid>
                </Grid>
            </Form>
        )
    }

    render() {
        const {isEdit, user, openSnackbar, variantSnackbar, messageSnackbar, openChangePwdDialog} = this.state;
        const inputUser = {...user, isEdit: isEdit};
        const Schema = Yup.object().shape({
            display_name: Yup.string().required("Username is required"),
            email: Yup.string().required("Email is required").email("Please input correct email address"),
            password: Yup.string().required("Password is required"),
            re_password: Yup.string().when("password", {
                is: val => (val && val.length > 0 ? true : false),
                then: Yup.string().oneOf(
                    [Yup.ref("password")],
                    "Both password need to be the same"
                )
            }).required("Re-Password is required"),
        })

        const SchemaChangePwd = Yup.object().shape({
            new_password: Yup.string().required("New Password is required"),
            re_new_password: Yup.string().when("new_password", {
                is: val => (val && val.length > 0 ? true : false),
                then: Yup.string().oneOf(
                    [Yup.ref("new_password")],
                    "Both password need to be the same"
                )
            }).required("Re-Password is required"),
        })

        const SchemaChangePwdUser = Yup.object().shape({
            old_password: Yup.string().required("Current Password is required"),
            new_password: Yup.string().required("New Password is required"),
            re_new_password: Yup.string().when("new_password", {
                is: val => (val && val.length > 0 ? true : false),
                then: Yup.string().oneOf(
                    [Yup.ref("new_password")],
                    "Both password need to be the same"
                )
            }).required("Re-Password is required"),
        })

        return (
            user && (
                <div>
                    <div className="main__container flex-center-item">
                        <Formik
                            enableReinitialize
                            initialValues={inputUser}
                            validationSchema={Schema}
                            onSubmit={this._submitUserForm}
                            component={this.formConfiguration}
                        />

                        <Dialog
                            open={openChangePwdDialog}
                            onClose={this.handleCloseChangePwd}
                            aria-labelledby="form-dialog-title"
                            disableBackdropClick={true}
                        >
                            <DialogTitle id="form-dialog-title">SET NEW PASSWORD</DialogTitle>
                            <DialogContent>
                                <Formik
                                    enableReinitialize
                                    initialValues={{
                                        old_password: '',
                                        new_password: '',
                                        re_new_password: '',
                                        role: inputUser.role
                                    }}
                                    validationSchema={inputUser.role === 2 ? SchemaChangePwdUser : SchemaChangePwd}
                                    onSubmit={this._submitChangePwdForm}
                                    component={this.formChangePwdConfiguration}
                                />
                            </DialogContent>
                        </Dialog>

                        <Snackbar
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={openSnackbar}
                            autoHideDuration={2000}
                            onClose={this.handleCloseSnackbar}
                        >
                            <MySnackbarContentWrapper
                                onClose={this.handleCloseSnackbar}
                                variant={variantSnackbar}
                                message={messageSnackbar}
                            />
                        </Snackbar>
                    </div>
                </div>
            )
        );
    }
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    breadcrumbArr: state.breadcrumb.breadcrumbArr
});
const mapDispatchToProps = dispatch => ({
    setBreadcrumbP: data => dispatch(setBreadcrumb(data)),
    getUserInfoP: data => dispatch(getUserInfo(data)),
});
export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(withRouter(Profile)));
