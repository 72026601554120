// Essential for all components
import React from 'react';

import EnhancedTableHead from "./EnhancedTableHead";
import TableBody from "@material-ui/core/TableBody";

import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Table from "@material-ui/core/Table";

import {withStyles} from "@material-ui/core/styles";
import EnhancedTablePagination from "./EnhancedTablePagination";
import Paper from "@material-ui/core/Paper";
import TablePagination from "@material-ui/core/TablePagination";
import {Checkbox} from "@material-ui/core";
import {Link, withRouter} from "react-router-dom";
import {withTranslation} from "react-i18next";
import moment from "moment";

const actionsStyles = theme => ({
    root: {
        flexShrink: 0,
        color: theme.palette.text.secondary,
        marginLeft: theme.spacing(2.5),
    },
});

const TablePaginationActionsWrapped = withStyles(actionsStyles, { withTheme: true })(
    EnhancedTablePagination,
);


class EnhancedTable extends React.Component {

    handleClick = (event, id) => {
        if (this.props.displaySelectBox && typeof this.props.selectRow === 'function') {
            this.props.selectRow(event, id);
        }
    }

    clickToFunction = (id) => {
        if (typeof this.props.clickToFunction === 'function') {
            this.props.clickToFunction(id);
        }
    }

    render() {
        const { i18n } = this.props;
        const {
            primaryKey,
            classesTable, rows, data, selected,
            order, orderBy, page, rowsPerPage, rowsPerPageOptions,
            displaySelectBox, amountOfData } = this.props;

        return (
            <Paper classes={{root: 'full-width'}}>
                <div>
                    <Table className={classesTable} aria-labelledby="tableTitle">
                        <EnhancedTableHead
                            page={page}
                            rowsPerPage={rowsPerPage}
                            enable_selectAll={displaySelectBox}
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={this.props.handleSelectAllClick}
                            onRequestSort={this.props.handleRequestSort}
                            rowCount={data ? data.length : 0}
                            rows={rows}
                        />
                        <TableBody>
                            {data
                                // .sort(getSorting(order, orderBy))
                                // .slice(page * parseInt(rowsPerPage), page * parseInt(rowsPerPage) + parseInt(rowsPerPage))
                                .map(n => {
                                    const theIndexNum = n[primaryKey];
                                    const isSelected = this.props.isSelected(n[primaryKey]);
                                    return (
                                        <TableRow
                                            hover
                                            onClick={event => this.handleClick(event, theIndexNum)}
                                            role="checkbox"
                                            aria-checked={isSelected}
                                            tabIndex={-1}
                                            key={theIndexNum}
                                            selected={isSelected}
                                        >
                                            { displaySelectBox && <TableCell padding="checkbox" style={{width: 50}}>
                                                                    <Checkbox checked={isSelected} />
                                                                </TableCell> }
                                            {
                                                rows.map( (cell, index) => {

                                                        if(cell.actionLink) {
                                                            return (
                                                                <TableCell key={index} style={ cell.width ? {width: cell.width} : {}}>
                                                                    <span className="custom-link" onClick={() => this.clickToFunction(n[cell.actionParam || cell.id])}>{n[cell.id]}</span>
                                                                </TableCell>
                                                            )
                                                        }

                                                        if(cell.link) {
                                                            let linkElm = null;
                                                            if(cell.customUrlParam) {
                                                                let url = cell.link;
                                                                cell.customUrlParam.forEach( item => {
                                                                    url = url.replace((':'+item), (n[item]));
                                                                });
                                                                linkElm = (<Link to={"/" + i18n.language + url}>{n[cell.id]}</Link>);
                                                            } else {
                                                                linkElm = (<Link to={"/" + i18n.language + cell.link.replace(':id', n[primaryKey])}>{n[cell.id]}</Link>);
                                                            }
                                                            return (
                                                                <TableCell key={index} style={ cell.width ? {width: cell.width} : {}}>
                                                                    {linkElm}
                                                                </TableCell>
                                                            )
                                                        }

                                                        let displayValue = null;
                                                        if(cell.type === 'date') {
                                                            displayValue = n[cell.id] ? moment(n[cell.id]).format(cell.dateFormat || 'YYYY-MM-DD HH:mm:ss') : '';
                                                        } else {
                                                            displayValue = n[cell.id];
                                                        }

                                                        return (
                                                            <TableCell key={index} style={ cell.width ? {width: cell.width} : {}}>
                                                                { displayValue }
                                                            </TableCell>
                                                        )
                                                    }
                                                )
                                            }
                                        </TableRow>
                                    );
                                })}
                            {/*{*/}
                            {/*    emptyRows > 0 && (*/}
                            {/*        <TableRow style={{ height: 49 * emptyRows }}>*/}
                            {/*            <TableCell colSpan={displaySelectBox ? rows.length + 1 : rows.length} />*/}
                            {/*        </TableRow>*/}
                            {/*    )*/}
                            {/*}*/}
                        </TableBody>
                    </Table>
                </div>
                <TablePagination
                    classes={{
                        root: 'custom-paging-root',
                        spacer: 'custom-paging-spacer',
                        toolbar: 'custom-paging-toolbar',
                        caption: 'custom-paging-caption',
                        selectRoot: 'custom-paging-selectRoot',
                        select: 'custom-paging-select',
                        input: 'custom-paging-select',
                        menuItem: 'custom-paging-menuItem'
                    }}
                    component="div"

                    count={ parseInt(amountOfData? amountOfData : 0) }
                    rowsPerPage={parseInt(rowsPerPage)}
                    rowsPerPageOptions={rowsPerPageOptions ? rowsPerPageOptions : [5, 10, 15, 25]}
                    page={page}
                    SelectProps={{
                        native: true,
                    }}
                    backIconButtonProps={{
                        'aria-label': 'Previous Page',
                    }}
                    nextIconButtonProps={{
                        'aria-label': 'Next Page',
                    }}
                    labelDisplayedRows={({ from, to, count }) =>
                        `${from} to ${to} of ${count}`
                    }
                    onChangePage={this.props.handleChangePage}
                    onChangeRowsPerPage={this.props.handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActionsWrapped}
                />
            </Paper>
        );
    }
}
export default withTranslation()(withRouter(EnhancedTable));
