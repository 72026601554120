// Essential for all components
import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import {withRouter} from 'react-router-dom';
// Redux
import {connect} from 'react-redux';
// Material UI
import Grid from '@material-ui/core/Grid';
// Include
import {getOrderData, getSelectedData} from "../../utils/02MaterialDesign/EnhancedTableUtil";
import EnhancedTable from "../../components/102MaterialDesign/EnhancedTable/EnhancedTable";
import {setBreadcrumb} from "../../Redux/Action/breadcrumbAction";
import {apiConfiguration} from "../../Api/_ApiConfiguration";
import moment from "moment";
import {getValAfterSlash, handleSpecialFieldName} from "../../utils/PaginationUtils";
import {setPaginator} from "../../Redux/Action/paginatorAction";


// Define column names
function getRows(props) {
    const platform = encodeURIComponent(props.platform);
    return [
        {id: 'name', numeric: false, disablePadding: false, label: 'Parameter', link: `/configuration/${platform}/:id/view`},
        {id: 'fix_value', numeric: true, disablePadding: false, label: 'Fixed Value'},
        {id: 'percentage', numeric: true, disablePadding: false, label: 'Percentage'},
        {id: 'description', numeric: false, disablePadding: false, label: 'Description'},
        {id: 'update_dated', numeric: false, disablePadding: false, label: 'Update Date'}
    ];
}

const rowsPerPageDefault = 25;
const orderByDefault = 'sequence';
const orderDefault = 'asc';

class ConfigurationList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            // table settings
            order: orderDefault,
            orderBy: orderByDefault,
            selected: [],
            page: 0,
            rowsPerPage: rowsPerPageDefault,
            rows: getRows(props),
    
            // component state
            data: [],
            search_type: '',
            search_keyword: '',
            amountOfData: '',
        };
    }

    componentDidMount() {
        this._getDataDynamic(0, rowsPerPageDefault, orderByDefault, orderDefault, true);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.platform !== prevProps.platform) {
            this.setState({
                rows: getRows(this.props)
            });
        }
    }

    _getDataDynamic = (skipAmount, rowsPerPage, orderBy, order, isResetNavAndDataAmount, searchSubject) => {
        orderBy = handleSpecialFieldName(orderBy);
        const params = {
            '$orderby': `${orderBy} ${order}`,
            '$top': rowsPerPage,
            '$skip': skipAmount,
            '$select': 'lastmoddate,name,value,sequence,description,default_value,configuration_id,fix_value,percentage'
        };
        apiConfiguration.getConfigurationList(this.props.platform, params).then(obj => {
            if (obj && obj.status === 200) {
                if (isResetNavAndDataAmount) {
                    const dataSum = getValAfterSlash(obj.headers['content-range']);
                    this.setState({amountOfData: dataSum});
                    this.props.setBreadcrumbP(
                        {
                            breadcrumbData: [
                                {
                                    title: `Configuration ${this.props.platform}`,
                                    link: null
                                }
                            ]
                        }
                    );
                }

                this.setState({
                    search_keyword: searchSubject,
                    data: this.prepareData(obj.data)
                });
            } else {
                console.log("eCb : ", obj);
            }
        })
    }

    prepareData = data => {
        return (data && data.length > 0) ? (
            data.map(item => ({
                ...item,
                id: item.configuration_id,
                update_dated: moment(item.lastmoddate).format('YYYY-MM-DD HH:mm:ss'),
            }))
        ) : [];
    };

    handleRequestSort = (event, property) => {
        const {page, rowsPerPage, search_keyword} = this.state;
        const orderData = getOrderData(property, this.state.orderBy, this.state.order);
        this.setState({order: orderData.order, orderBy: orderData.orderBy}, () => {
            this.settingPreviousPaginator(rowsPerPage, page, orderData.orderBy, orderData.order);
            this._getDataDynamic(page * rowsPerPage, rowsPerPage, orderData.orderBy, orderData.order, true, search_keyword);
        });
    };

    handleSelectAllClick = event => {
        if (event.target.checked) {
            this.setState(state => ({selected: state.data.map(n => n.id)}));
            return;
        }
        this.setState({selected: []});
    };

    handleClick = (event, id) => {
        const {selected} = this.state;
        const newSelected = getSelectedData(id, selected);
        this.setState({selected: newSelected});
    };

    handleChangePage = (event, page) => {
        if (event) {
            const {rowsPerPage, order, orderBy} = this.state;
            this.setState({
                selected: [],
                page: page
            });
            this.settingPreviousPaginator(rowsPerPage, page, orderBy, order);
            this._getDataDynamic(page * rowsPerPage, rowsPerPage, orderBy, order, false);
        }
    };

    handleChangeRowsPerPage = event => {
        const {page, order, orderBy, search_keyword} = this.state;
        this.setState({rowsPerPage: parseInt(event.target.value)});
        this.settingPreviousPaginator(parseInt(event.target.value), page, orderBy, order);
        this._getDataDynamic(page * event.target.value, event.target.value, orderBy, order, false, search_keyword);
    };

    settingPreviousPaginator = (rowsPerPage, page, orderBy, order) => {
        this.props.setPaginator({
            page_id: 'configuration',
            rowsPerPage: rowsPerPage,
            page: page,
            orderBy: orderBy,
            order: order
        })
    }

    isSelected = id => this.state.selected.indexOf(id) !== -1;

    render() {
        const {rows, data, order, orderBy, selected, rowsPerPage, page, amountOfData} = this.state;
        return (
            <div className="configuration-page">
                <div className="main__container">
                    <Grid container alignItems="center">
                        {
                            data && <EnhancedTable
                                pageId={'configuration'}
                                primaryKey='configuration_id'
                                displaySelectBox={false}
                                rows={rows}
                                data={data}
                                currentPage={page}
                                selected={selected}
                                order={order}
                                orderBy={orderBy}
                                page={page}
                                rowsPerPage={rowsPerPage}
                                amountOfData={amountOfData}
                                isSelected={this.isSelected}
                                selectRow={this.handleClick}
                                handleSelectAllClick={this.handleSelectAllClick}
                                handleRequestSort={this.handleRequestSort}
                                handleChangePage={this.handleChangePage}
                                handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                            />
                        }
                    </Grid>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    currentPaginator: state.paginator.currentPaginator
});
const mapDispatchToProps = dispatch => ({
    setBreadcrumbP: data => dispatch(setBreadcrumb(data)),
    setPaginator: data => dispatch(setPaginator(data))
});
export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(withRouter(ConfigurationList)));