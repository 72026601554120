import { api, getBaseUrl } from "./_ApiFactoryWithHeader";

export const apiConfiguration = {
    getConfigurationList: (platform, params, cancelToken) => {
        return getBaseUrl(platform).then(baseUrl => {
            const url = baseUrl + '/configurations';
            return api.get(url, params, null, cancelToken);
        });
    },
    getConfigurationDetail: (platform, id, params, cancelToken) => {
        return getBaseUrl(platform).then(baseUrl => {
            const url = baseUrl + '/configurations/' + id;
            return api.get(url, params, null, cancelToken);
        });
    },
    updateConfiguration: (platform, id, body) => {
        return getBaseUrl(platform).then(baseUrl => {
            const url = baseUrl + '/configurations/' + id;
            return api.put(url, body);
        });
    },
};