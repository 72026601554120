// export const WEBSITE_URL = 'http://localhost:8080';
// export const WEBSITE_URL = '';
export const WEBSITE_URL = 'https://www.egobidder.com';
export const PARTIAL_API_URL = WEBSITE_URL + '/egobid-server-';
export const API_CENTRAL_URL_BASE = PARTIAL_API_URL + 'core';
export const API_CENTRAL_URL = API_CENTRAL_URL_BASE + '/';
export const GA_TRACKING_ID = 'UA-xxxxxxxx';

export const SCREEN_SIZE_SM = 768;
export const SCREEN_SIZE_MD = 992;
export const SCREEN_SIZE_LG = 1200;
