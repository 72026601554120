// Essential for all components
import React, {Component} from 'react';
import {withTranslation} from "react-i18next";
import {withRouter} from "react-router-dom";

import Grid from "@material-ui/core/Grid";
import {Button} from "@material-ui/core";

import BaseListPage from "../../components/101BaseListPage/BaseListPage";
import {setBreadcrumb} from "../../Redux/Action/breadcrumbAction";
import {connect} from "react-redux";
import moment from "moment";
import {getValAfterSlash, handleSpecialFieldName} from "../../utils/PaginationUtils";
import queryString from "query-string";
import {apiClient} from "../../Api/_ApiClient";
import {downLoadFile, formatCurrency} from "../../utils/Utility";

// Define column names
const historyRows = [
    {id: 'description', numeric: false, disablePadding: false, label: 'Description'},
    {id: 'credit_number', numeric: true, disablePadding: false, label: 'Credit'},
    {id: 'createddate', numeric: false, disablePadding: false, label: 'Created Date', width: 200, type: 'date'}
];

const rowsPerPageDefault = 10;
const orderByDefault = 'createddate';
const orderDefault = 'desc';

class ClientDetails extends Component {

    state = {
        clientDetail: null,
        histories: [],
        previous: false,
        amountOfData: '',
        currentPage: 0,
        rowsPerPage: rowsPerPageDefault,
        orderBy: orderByDefault,
        order: orderDefault,
    };

    componentDidMount() {
        let previousPage = null;
        const values = queryString.parse(this.props.location.search)
        if (values.previous) {
            previousPage = [...this.props.currentPaginator].find(item => item.page_id === 'client-detail');
        }

        const currentPage = previousPage ? previousPage.page : 0,
            rowsPerPage = previousPage ? previousPage.rowsPerPage : rowsPerPageDefault,
            orderBy = previousPage ? previousPage.orderBy : orderByDefault,
            order = previousPage ? previousPage.order : orderDefault;
        this.setState({
            previous: true,
            currentPage: currentPage,
            rowsPerPage: rowsPerPage,
            orderBy: orderBy,
            order: order
        }, () => {
            this.getDetail();
        });
    }

    getDetail = () => {
        const {t, i18n} = this.props;
        const params = {
            '$expand': 'user,client_platforms/platform,power_bid_platforms',
        };
        apiClient.getClientDetail(this.props.id, params).then(obj => {
            if (obj && obj.status === 200) {
                if (obj.data) {
                    const client = obj.data;
                    const userItemBreadcrumb = [
                        {
                            title: t('ClientManagement:breadcrumb'),
                            link: '/' + i18n.language + '/client-management'
                        },
                        {
                            title: client.display_name,
                            link: null
                        }
                    ];
                    this.props.setBreadcrumbP({breadcrumbData: userItemBreadcrumb});
                    this.setState({clientDetail: client});
                    const {currentPage, rowsPerPage, orderBy, order} = this.state;
                    this._getDataDynamic(currentPage * rowsPerPage, rowsPerPage, orderBy, order, true);
                }
            }
        })
    }

    _getDataDynamic = (skipAmount, rowsPerPage, orderBy, order, isResetNavAndDataAmount, searchSubject) => {
        orderBy = handleSpecialFieldName(orderBy);

        let params = {
            'client': this.props.id,
            '$orderby': `${orderBy} ${order}`,
            '$top': rowsPerPage,
            '$skip': skipAmount,
            '$select': 'credit_transaction_id,description,credit_number,createddate'
        }
        apiClient.getCreditHistories(params).then(rs => {
            if (rs && rs.status === 200) {
                if (isResetNavAndDataAmount) {
                    const dataSum = getValAfterSlash(rs.headers['content-range']);
                    this.setState({amountOfData: dataSum});
                }
                this.setState({
                    histories: rs.data
                });
            } else {
                console.log(rs.error)
            }
        })
    }

    back = () => {
        const {i18n} = this.props;
        this.props.history.push('/' + i18n.language + '/client-management?previous=true');
    }

    goToEditPage = () => {
        const {i18n} = this.props;
        this.props.history.push('/' + i18n.language + `/client/${this.props.id}`);
    }

    _createButtonAction = () => {
        const {i18n} = this.props;
        this.props.history.push('/' + i18n.language + `/add-credit/${this.props.id}`);
    }

    _exportButtonAction = () => {
        apiClient.exportCredit(this.props.id, {}).then(res => {
            if (res) {
                const fileName = `credit_history_${moment().format('YYYYMMDD')}.xlsx`;
                downLoadFile(res.data, fileName);
            } else {
                console.log(res);
            }
        })
    }

    _sendButtonAction = () => {
        const {i18n} = this.props;
        this.props.history.push('/' + i18n.language + `/send-msg/${this.props.id}`);
    }

    prepareData = data => {
        return (data && data.length > 0) ? (
            data.map(item => ({
                ...item,
                credit_number: item.credit_number ? formatCurrency(item.credit_number, '') : '',
                created_date: moment(item.createddate).format('YYYY-MM-DD HH:mm:ss'),
            }))
         ) : [];
    };

    render() {

        const {t} = this.props;
        const {clientDetail, histories, amountOfData, currentPage, rowsPerPage, orderBy, order} = this.state;

        return (
            clientDetail ? (
                <div>
                    <div className="main__container flex-center-item">
                        <div className="full-width client-detail">
                            <Grid container xm={12} alignItems="center">
                                <Grid container xm={12} direction="row" alignItems="flex-start">
                                    <Grid item xs={12} md={6} className="button-wrapper form-item">
                                        <Button type="button" className="primary-button m0" onClick={() => {
                                            this.goToEditPage()
                                        }}>{t("Common:Button.edit")} User</Button>
                                    </Grid>
                                    <Grid item xs={12} md={6} className="button-wrapper form-item flex-end-item">
                                        <Grid container xm={12} alignItems="center" className="flex-column-end">
                                            <Button type="button" className="send-button" onClick={() => {
                                                this._sendButtonAction()
                                            }}>Send One-2-One Message</Button>
                                            <span>*Can send only when the Client is online</span>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item xs={3} className="form-item">
                                    {t("ClientManagement:userName")}
                                </Grid>
                                <Grid item xs={9} className="form-item">
                                    {clientDetail.display_name}
                                </Grid>

                                <Grid item xs={3} className="form-item">
                                    {t("ClientManagement:wechat")}
                                </Grid>
                                <Grid item xs={9} className="form-item">
                                    {clientDetail.wechat_id}
                                </Grid>
                                <Grid item xs={3} className="form-item">
                                    {t("ClientManagement:email")}
                                </Grid>
                                <Grid item xs={9} className="form-item">
                                    {clientDetail.email}
                                </Grid>
                                <Grid item xs={3} className="form-item">
                                    {t("ClientManagement:phone")}
                                </Grid>
                                <Grid item xs={9} className="form-item">
                                    {clientDetail.phone}
                                </Grid>
                                <Grid item xs={3} className="form-item">
                                    {t("ClientManagement:active")}
                                </Grid>
                                <Grid item xs={9} className="form-item">
                                    {clientDetail.active ? 'Yes' : 'No'}
                                </Grid>
                                <Grid item xs={3} className="form-item">
                                    {t("ClientManagement:creditBalance")}
                                </Grid>
                                <Grid item xs={9} className="form-item">
                                    {formatCurrency(clientDetail.credit, '')}
                                </Grid>
                                <Grid item xs={3} className="form-item">
                                    {t("ClientManagement:maxCredit")}
                                </Grid>
                                <Grid item xs={9} className="form-item">
                                    {formatCurrency(clientDetail.max_credit, '')}
                                </Grid>
                                <Grid item xs={3} className="form-item">
                                    {t("ClientManagement:platforms")}
                                </Grid>
                                <Grid item xs={9} className="form-item">
                                    {clientDetail.client_platforms.length > 0 ? (
                                        clientDetail.client_platforms.map(({ platform }) => platform.name).join(', ')
                                    ) : 'N/A'}
                                </Grid>
                                <Grid item xs={3} className="form-item">
                                    {t("ClientManagement:powerBid")}
                                </Grid>
                                <Grid item xs={9} className="form-item">
                                    {clientDetail.power_bid_platforms.length > 0 ? (
                                        clientDetail.power_bid_platforms.map(({ name }) => name).join(', ')
                                    ) : 'N/A'}
                                </Grid>
                                <Grid item xs={3} className="form-item">
                                    {t("ClientManagement:extendedBid")}
                                </Grid>
                                <Grid item xs={9} className="form-item">
                                    {clientDetail.client_platforms.filter(cp => cp.extended_bid).length > 0 ? (
                                        clientDetail.client_platforms
                                            .filter(cp => cp.extended_bid)
                                            .map(({ platform }) => platform.name).join(', ')
                                    ) : 'N/A'}
                                </Grid>
                                <Grid item xs={3} className="form-item">
                                    {t("ClientManagement:userHash")}
                                </Grid>
                                <Grid item xs={9} className="form-item">
                                    {clientDetail.user ? clientDetail.user.user_hash : 'N/A'}
                                </Grid>
                                <Grid item xs={3} className="form-item">
                                    {t("ClientManagement:updatedDate")}
                                </Grid>
                                <Grid item xs={9} className="form-item">
                                    {moment(clientDetail.lastmoddate).format('YYYY-MM-DD HH:mm:ss')}
                                </Grid>
                                <Grid item xs={3} className="form-item">
                                </Grid>

                                <Grid item xs={12} className="pt30">
                                    <h4>Credit History:</h4>
                                    <BaseListPage
                                        pageId={'credit-history'}
                                        mainClass={'credit-history'}
                                        primaryKey={'credit_transaction_id'}
                                        displaySearchBar={false}

                                        displayActionBar={true}

                                        createButton={true}
                                        createButtonText={'Add Credit'}
                                        createButtonAction={this._createButtonAction}

                                        exportButton={true}
                                        exportButtonText="Export CSV"
                                        exportButtonAction={this._exportButtonAction}

                                        getDataDynamic={this._getDataDynamic}
                                        currentPage={currentPage}
                                        rows={historyRows}
                                        data={this.prepareData(histories)}
                                        displaySelectBox={true}
                                        orderDefault={order}
                                        orderByDefault={orderBy}
                                        rowsPerPageDefault={rowsPerPage}
                                        rowsPerPageOptions={[5, 10, 20]}
                                        amountOfData={amountOfData}
                                    />
                                </Grid>
                                <Grid item xs={12} className="button-wrapper form-item pt20">
                                    <Button type="button" className="back-button" onClick={() => {
                                        this.back()
                                    }}>Back</Button>
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                </div>
            ) : null
        );
    }
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    currentPaginator: state.paginator.currentPaginator
});
const mapDispatchToProps = dispatch => ({
    setBreadcrumbP: data => dispatch(setBreadcrumb(data))
});
export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(withRouter(ClientDetails)));
