// Essential for all components
import React, {Component} from 'react';
import {withTranslation} from "react-i18next";
import {withRouter} from "react-router-dom";

import get from 'lodash-es/get';
import keyBy from 'lodash-es/keyBy';
import {connect} from "react-redux";
import {setBreadcrumb} from "../../Redux/Action/breadcrumbAction";
import Grid from "@material-ui/core/Grid";
import {Button, Checkbox, FormControlLabel} from "@material-ui/core";
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import {Field, Form, Formik} from "formik";
import * as Yup from "yup";

import ErrorMessage from "../../components/100Include/ErrorMessage";
import moment from "moment";
import DatePickerField from "../../components/103FormikCustom/DatePickerField";
import {apiTenant} from "../../Api/_ApiTenant";
import {addMessage} from "../../Redux/Action/messageAction";

class TenantUpdate extends Component {

    constructor(props) {
        super(props);
        this.state = {
            source: '',
            suppliers: [],
            supplierMap: {},
            isEdit: false,
            tenant: {
                tenant_id: '',
                name: '',
            },
            tenantPlatformSuppliers: [],
            tenantPlatformSuppliersValidateMsg: '',
        };
    }

    componentDidMount() {
        this.getListSupplier();
        if(this.props.id) {
            this.setState({isEdit: true})
            this.getTenantDetail();
        } else {
            const { t } = this.props;
            const breadcrumb = [{
                title: t("TenantManagement:addTenant"),
                link: null
            }];
            this.props.setBreadcrumbP({breadcrumbData: breadcrumb});
        }
    }

    getListSupplier = () => {
        const params = {
            '$orderby': 'name asc',
        };
        apiTenant.getSuppliers(params).then(obj => {
            if(obj && obj.status === 200) {
                if(obj.data) {
                    const suppliers = obj.data;
                    const supplierMap = keyBy(suppliers, 'supplier_id');
                    this.setState({ 
                        suppliers,
                        supplierMap
                    });
                }
            } else {
                console.log(obj.error)
            }
        })
    }
    getTenantDetail = () => {
        const params = {
            $expand: "tenant_platforms"
        };
        apiTenant.getTenantDetail(this.props.id, params).then(obj => {
            if(obj && obj.status === 200) {
                if(obj.data && obj.data) {
                    const tenantDetail = obj.data;
                    this.setState({
                        tenant: this.prepareData(tenantDetail),
                        tenantPlatformSuppliers: tenantDetail.tenant_platforms.map((tenantPlatform) => {
                            return tenantPlatform.platform;
                        }),
                    });
                    const userItemBreadcrumb = [{
                        title: `Edit Tenant [${tenantDetail.name}]`,
                        link: null
                    }];
                    this.props.setBreadcrumbP({breadcrumbData: userItemBreadcrumb});
                }
            } else {
                console.log(obj.error)
            }
        })
    }

    prepareData = data => {
        if(data) {
            const item = {...data};
            item.update_date = item.lastmoddate ? moment(item.lastmoddate).format('YYYY-MM-DD HH:mm:ss') : '';
            item.start_date = moment(item.start_time).format('YYYY-MM-DD');
            item.selected_start_time = moment(item.start_time).format('HH:mm');
            item.end_date = moment(item.end_time).format('YYYY-MM-DD');
            item.selected_end_time = moment(item.end_time).format('HH:mm');
            return item;
        }
        return null;
    };

    // BUTTON FUNCTION
    handleCloseSnackbar = () => {
        this.setState({ openSnackbar: false });
    };

    cancel = () => {
        const { i18n } = this.props;
        this.props.history.push('/' + i18n.language + '/tenant-management?previous=true');
    }

    eCb = (obj) => {
        console.log("eCb : ", obj);
    }

    _addTenantAsync = (value) => {
        const { i18n } = this.props;
        const { tenantPlatformSuppliers } = this.state;

        const body = {
            name: value.name,
            color: '#0374AB',
            text_color: '#FFFFFF',
            tenant_platforms: tenantPlatformSuppliers.map(tenantPlatformSupplier => {
                return {
                    platform: tenantPlatformSupplier
                };
            }),
        }
        apiTenant.addTenant(body).then(obj => {
            if (obj.status === 201) {
                const msgDsp = {
                    messageSnackbar: 'Add new Tenant successfully',
                    variantSnackbar: 'success',
                    key: new Date().getTime(),
                };
                //Add msg
                this.props.addMessageP(msgDsp);
                this.props.history.push('/' + i18n.language + '/tenant-management?previous=true')
            } else {
                const error = obj.data && obj.data.error ? obj.data.error : 'Add new tenant failed';
                //Add msg
                this.props.addMessageP({
                    messageSnackbar: error,
                    variantSnackbar: 'error',
                    key: new Date().getTime(),
                });
            }
        })
    }

    _updateTenantAsync = (value) => {
        const { i18n } = this.props;
        const { tenantPlatformSuppliers } = this.state;

        apiTenant.getTenantDetail(this.props.id, null).then(obj => {
            if(obj && obj.status === 200) {
                if(obj.data && obj.data) {
                    const tenantDetail = obj.data;
                    tenantDetail.name = value.name;
                    tenantDetail.tenant_platforms = tenantPlatformSuppliers.map(tenantPlatformSupplier => {
                        return {
                            platform: tenantPlatformSupplier
                        };
                    });
                    return apiTenant.fullUpdateTenant(this.props.id, tenantDetail, {
                        $expand: 'tenant_platforms'
                    });
                }
            } else {
                const error = obj.data && obj.data.error ? obj.data.error : 'Update tenant failed';
                //Add msg
                this.props.addMessageP({
                    messageSnackbar: error,
                    variantSnackbar: 'error',
                    key: new Date().getTime(),
                });
            }
        }).then(obj => {
            if (obj.status === 200) {
                const msgDsp = {
                    messageSnackbar: 'Update tenant successfully',
                    variantSnackbar: 'success',
                    key: new Date().getTime(),
                };
                //Add msg
                this.props.addMessageP(msgDsp);
                this.props.history.push('/' + i18n.language + '/tenant-management?previous=true')
            } else {
                const error = obj.data && obj.data.error ? obj.data.error : 'Update tenant failed';
                //Add msg
                this.props.addMessageP({
                    messageSnackbar: error,
                    variantSnackbar: 'error',
                    key: new Date().getTime(),
                });
            }
        })
    }

    _submitTenantForm = (value) => {
        if(this.state.isEdit) {
            this._updateTenantAsync(value);
        } else {
            this._addTenantAsync(value);
        }
    }

    handleChange = event => {
        const {
            target: { value },
        } = event;
        const arrayValue = typeof value === 'string' ? value.split(',') : value;
        this.setState({
            tenantPlatformSuppliers: arrayValue,
            tenantPlatformSuppliersValidateMsg: arrayValue.length === 0 ? 'Tenant platforms cannot be empty' : null
        });
    };

    // FORM CONFIG
    formConfiguration = ({ values, errors, touched, handleChange, setFieldValue }) => {
        const { t, id } = this.props;
        const { suppliers, supplierMap } = this.state;
        const tenantPlatformSuppliersValidate = this.state.tenantPlatformSuppliersValidateMsg ? (
            <ErrorMessage message={this.state.tenantPlatformSuppliersValidateMsg} />
        ) : t("Common:Form.validator.required");

        return values && (
            <Form id="tenantForm" className="full-width">
                <Grid container xm={12} alignItems="center">
                    {this.state.MessageContent &&
                    <Grid item xs={12} className="ErrorMessage form-item">
                        <ErrorMessage
                            message={this.state.MessageContent}
                        />
                    </Grid>
                    }

                    <Grid container direction="row" justify="center" alignItems="flex-start" >
                        <Grid item xs={3} className="form-item">
                            {t("TenantManagement:tenantName")}
                        </Grid>
                        <Grid item xs={9} className="form-item">
                            <Grid item xs={12}>
                                <Field id="display_name" name="name" type="text" placeholder="" />
                            </Grid>
                            <Grid item xs={12}>
                                {errors.name && touched.name ? <ErrorMessage message={errors.name} /> : t("Common:Form.validator.required")}
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container direction="row" justify="center" alignItems="flex-start" className="pt20">
                        <Grid item xs={3} className="form-item">
                            {t("TenantManagement:platforms")}
                        </Grid>
                        <Grid item xs={9} className="form-item">
                            <Grid item xs={12}>
                                <FormControl variant="outlined" className='fifty' >
                                    <Select
                                        classes={{
                                            root: 'custom-select'
                                        }}
                                        multiple
                                        value={this.state.tenantPlatformSuppliers}
                                        onChange={e => {
                                            return this.handleChange(e);
                                        }}
                                        renderValue={(value) => {
                                            let result;
                                            if (value) {
                                                if (value.length > 0) {
                                                    result = value.map(v => {
                                                        const supplier = supplierMap[v];
                                                        return supplier.name;
                                                    }).join(', ');
                                                } else if (value.length <= 0) {
                                                    result = '';
                                                } else {
                                                    result = value;
                                                }
                                            } else {
                                                result = '';
                                            }
                                            return result;
                                        }}
                                    >
                                        {suppliers.map(item => (
                                            <MenuItem key={item.supplier_id} value={item.supplier_id}>{item.name}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>

                            </Grid>
                            <Grid item xs={12}>
                                { tenantPlatformSuppliersValidate }
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={2} className="form-item">
                    </Grid>
                    <Grid item xs={12} className="button-wrapper form-item pt20">
                        <Button type="submit" className="primary-button">{id ? 'Save' : 'Add'}</Button>
                        <Button type="button" className="second-button" onClick={() => { this.cancel() }}>Cancel</Button>
                    </Grid>
                </Grid>
            </Form>
        )
    }

    render() {
        const { isEdit, tenant } = this.state;
        const inputTenant = {...tenant, isEdit: isEdit};
        const Schema = Yup.object().shape({
            name: Yup.string().required("Name is required"),
        })

        return (
            tenant && (
                <div>
                    <div className="main__container flex-center-item">
                        <Formik
                            enableReinitialize
                            initialValues={inputTenant}
                            validationSchema={Schema}
                            onSubmit={this._submitTenantForm}
                            component={this.formConfiguration}
                        />
                    </div>
                </div>
            )
        );
    }
}
const mapStateToProps = (state) => ({
    auth: state.auth,
    breadcrumbArr: state.breadcrumb.breadcrumbArr
});
const mapDispatchToProps = dispatch => ({
    setBreadcrumbP: data => dispatch(setBreadcrumb(data)),
    addMessageP: data => dispatch(addMessage(data)),
});
export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(withRouter(TenantUpdate)));
