// Essential for all components
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Redirect} from 'react-router';
import './css/App.scss';
import {withTranslation} from 'react-i18next';
// Redux
import {connect} from 'react-redux';
import {login, logout} from './Redux/Action/authAction';
import {setBreadcrumb} from "./Redux/Action/breadcrumbAction";
//Material
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import {withStyles} from '@material-ui/core/styles';

import {get} from 'lodash-es';
import querySearch from "stringquery";
import {setAccessToken, setForceLogout, setforceLogoutMsg} from "./Api/_ApiFactoryWithHeader";
import {CacheService} from "./utils/CacheService";
import {apiLanguages} from "./Api/_ApiLanguages";

// Component
import Sidebar from "./components/100Include/Sidebar";
import PublicHeader from "./components/100Include/PublicHeader";
import PrivateHeader from "./components/100Include/PrivateHeader";
// Home
import ForgetPassword from './containers/00Home/ForgetPassword';
import ResetPassword from './containers/00Home/ResetPassword';
import Login from "./containers/00Home/Login";
import ResetPasswordSuccess from "./containers/00Home/ResetPasswordSuccess";
// 404
import PageNotFound from './containers/PageNotFound';
// User Manager
import Profile from "./containers/02Profile/Profile";
import {MySnackbarContentWrapper} from "./components/102MaterialDesign/MySnackbarContentWrapper";
import Snackbar from "@material-ui/core/Snackbar";
import AuctionManagement from "./containers/01AuctionManagement/AuctionManagement";
import AuctionUpdate from "./containers/01AuctionManagement/AuctionUpdate";
import ClientManagement from "./containers/02ClientManagement/ClientManagement";
import ClientDetails from "./containers/02ClientManagement/ClientDetails";
import ClientUpdate from "./containers/02ClientManagement/ClientUpdate";
import AddCredit from "./containers/02ClientManagement/AddCredit";
import AddTenantCredit from "./containers/02ClientManagement/AddTenantCredit";
import OneToOneMsg from "./containers/02ClientManagement/OneToOneMsg";
import SendPushMsg from "./containers/02ClientManagement/SendPushMsg";
import SendWeChat from "./containers/02ClientManagement/SendWeChat";
import PushMsgSchedule from "./containers/03PushMsgSchedule/PushMsgSchedule";
import SendPushMsgUpdate from "./containers/03PushMsgSchedule/SendPushMsgUpdate";
import ConfigurationView from "./containers/05Configuration/ConfigurationView";
import ConfigurationDetail from "./containers/05Configuration/ConfigurationDetail";
import ConfigurationList from "./containers/05Configuration/ConfigurationList";
import ConfigurationPlatformList from "./containers/05Configuration/ConfigurationPlatformList";
import ReportList from "./containers/04Report/ReportList";
import ReportPlatformList from './containers/04Report/ReportPlatformList';
import {setMessages} from "./Redux/Action/messageAction";
import AuctionDetails from "./containers/01AuctionManagement/AuctionDetails";
import AuctionFileUpdate from './containers/01AuctionManagement/AuctionFileUpdate';
import IndustryManagement from './containers/06IndustryManagement/IndustryManagement';
import IndustryDetails from './containers/06IndustryManagement/IndustryDetails';
import IndustryUpdate from './containers/06IndustryManagement/IndustryUpdate';
import IndustryAttributeUpdate from './containers/06IndustryManagement/IndustryAttributeUpdate';
import TenantManagement from "./containers/06TenantManagement/TenantManagement";
import TenantDetails from "./containers/06TenantManagement/TenantDetails";
import TenantUpdate from "./containers/06TenantManagement/TenantUpdate";
import TenantMemberManagement from "./containers/02ClientManagement/TenantMemberManagement";
import TenantMemberDetails from "./containers/02ClientManagement/TenantMemberDetails";
import TenantMemberUpdate from "./containers/02ClientManagement/TenantMemberUpdate";

// Using Material for custom layout and
const drawerWidth = 260;
const styles = theme => ({
    root: {
        display: 'flex',
    },
    drawer: {
        [theme.breakpoints.up('sm')]: {
            width: drawerWidth,
            flexShrink: 0,
        },
    },
    footer: {
        width: `100%`,
        top: 'auto',
        bottom: 0
    },
    menuButton: {
        marginRight: 20,
        [theme.breakpoints.up('sm')]: {
            display: 'none',
        },
    },
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
        width: drawerWidth,
        background: '#3D3834'
    },
    content: {
        width: `100%`,
        minHeight: '100vh',
        flexGrow: 1
    }
});

class App extends Component {

    queue = [];

    // change URL
    constructor(props) {
        super(props);
        this.state = {
            debug: false,
            mobileOpen: false,
            openSnackbar: false,
            messageInfo: {},
        }

        setForceLogout(()=>this.props.logoutP());
        const authData = CacheService.getAuthData();
        if (authData) {
            setAccessToken(authData.access_token);
        }
    }

    componentDidUpdate = () => {
        window.scrollTo(0, 0);

        // check if token has expired
        // if (this.props.auth.auth) {
        //     const languages = localStorage.getItem("languages")
        //     if(!languages) {
        //         this.getLanguages();
        //     }
        // }

        const authData = CacheService.getAuthData();
        if (authData && this.props.auth.auth && authData.access_token !== this.props.auth.token) {
            let data = {
                token: authData.access_token,
                refreshToken: authData.refresh_token,
            }
            this.props.loginP(data);
        }

        if(this.props.messages.msgArr && this.props.messages.msgArr.length > 0) {
            this.displayMsg();
        }
    }

    handleDrawerToggle = () => {
        this.setState(state => ({ mobileOpen: !state.mobileOpen }));
    };

    getLanguages = () => {
        apiLanguages.getLanguages(null).then(obj => {
            if(obj && obj.status === 200) {
                localStorage.setItem("languages", JSON.stringify(obj.data));
            }
        })
    }

    // Message display
    displayMsg = () => {
        if (this.state.openSnackbar) {
            this.setState({ openSnackbar: false });
            this.processQueue();
        } else {
            this.processQueue();
        }
    }

    processQueue = () => {
        if (this.props.messages.msgArr && this.props.messages.msgArr.length > 0) {
            let msgArray = [...this.props.messages.msgArr];
            const msg = {...msgArray.shift()}
            this.setState({
                messageInfo: msg,
                openSnackbar: true,
                uploading: false,
            }, () => {
               this.props.setMessagesP(msgArray);
            });
        }
    };

    handleExited = () => {
        this.processQueue();
    };

    handleCloseSnackbar = (event, reason) => {
        if (reason && ['click', 'timeout'].includes(reason)) {
            this.setState({ openSnackbar: false });
        }
    };
    // END MESSAGE

    getComponent = (urlArray, params) => {
        let locate = urlArray[1],
            currentURL = urlArray[2],
            param1 = urlArray[3],
            param2 = urlArray[4],
            param3 = urlArray[5],
            param4 = urlArray[6];

        if (currentURL) {

            switch (currentURL) {
                case 'login': {
                    return <Login />;
                }
                case 'forget-password': {
                    return <ForgetPassword />;
                }
                case 'reset-password': {
                    return <ResetPassword />;
                }
                case 'reset-password-success': {
                    return <ResetPasswordSuccess />;
                }

                /*** USER MANAGEMENT ***/
                case 'profile': {
                    if (!this.props.auth.auth) {
                        return <Redirect push to={"/" + this.props.i18n.language + '/login'} />;
                    }
                    return <Profile />;
                }

                /*** AUCTION MANAGEMENT ***/
                case 'auction-management': {
                    if (!this.props.auth.auth) {
                        return <Redirect push to={"/" + this.props.i18n.language + '/login'} />;
                    }
                    if (param1) {
                        if (param2 && param2 === 'files' && param3) {
                            return <AuctionFileUpdate id={param3} />
                        } else {
                            return <AuctionDetails id={param1} />;
                        }
                    } else {
                        return <AuctionManagement />;
                    }
                }
                case 'auction': {
                    if (!this.props.auth.auth) {
                        return <Redirect push to={"/" + this.props.i18n.language + '/login'} />;
                    }
                    return <AuctionUpdate id={param1} />;
                }

                /*** CLIENT MANAGEMENT ***/
                case 'client-management': {
                    if (!this.props.auth.auth) {
                        return <Redirect push to={"/" + this.props.i18n.language + '/login'} />;
                    }
                    if (param1) {
                        return <TenantMemberDetails id={param1} />;
                    } else {
                        return <TenantMemberManagement />;
                    }
                }
                case 'client': {
                    if (!this.props.auth.auth) {
                        return <Redirect push to={"/" + this.props.i18n.language + '/login'} />;
                    }
                    return <TenantMemberUpdate id={param1} />;
                }
                case 'add-credit': {
                    if (!this.props.auth.auth) {
                        return <Redirect push to={"/" + this.props.i18n.language + '/login'} />;
                    }
                    return <AddCredit id={param1} />;
                }
                case 'add-tenant-credit': {
                    if (!this.props.auth.auth) {
                        return <Redirect push to={"/" + this.props.i18n.language + '/login'} />;
                    }
                    return <AddTenantCredit id={param1} />;
                }
                case 'send-msg': {
                    if (!this.props.auth.auth) {
                        return <Redirect push to={"/" + this.props.i18n.language + '/login'} />;
                    }
                    return <OneToOneMsg id={param1} />;
                }
                case 'send-push-msg': {
                    if (!this.props.auth.auth) {
                        return <Redirect push to={"/" + this.props.i18n.language + '/login'} />;
                    }
                    return <SendPushMsg id={param1} />;
                }
                case 'send-wechat': {
                    if (!this.props.auth.auth) {
                        return <Redirect push to={"/" + this.props.i18n.language + '/login'} />;
                    }
                    return <SendWeChat id={param1} />;
                }

                case 'push-msg-schedule': {
                    if (!this.props.auth.auth) {
                        return <Redirect push to={"/" + this.props.i18n.language + '/login'} />;
                    }
                    if (param1) {
                        return <SendPushMsgUpdate id={param1} />;
                    } else {
                        return <PushMsgSchedule />;
                    }
                }

                /*** Report ***/
                case 'report': {
                    if (!this.props.auth.auth) {
                        return <Redirect push to={"/" + this.props.i18n.language + '/login'} />;
                    }
                    if (param1) {
                        return <ReportList platform={param1}/>;
                    } else {
                        return <ReportPlatformList />;
                    }
                }

                /*** CONFIGURATION ***/
                case 'configuration': {
                    if (!this.props.auth.auth) {
                        return <Redirect push to={"/" + this.props.i18n.language + '/login'} />;
                    }
                    if (param1) {
                        if (param2) {
                            if (param3 === 'view') {
                                return <ConfigurationView platform={param1} id={param2} />
                            } else {
                                return <ConfigurationDetail platform={param1} id={param2} />;
                            }
                        } else {
                            return <ConfigurationList platform={param1}/>;
                        }
                    } else {
                        return <ConfigurationPlatformList />;
                    }
                }

                /*** INDUSTRY MANAGEMENT ***/
                case 'industry-management': {
                    if (!this.props.auth.auth) {
                        return <Redirect push to={"/" + this.props.i18n.language + '/login'} />;
                    }
                    if (param1) {
                        return <IndustryDetails id={param1} />;
                    } else {
                        return <IndustryManagement />;
                    }
                }
                case 'industry': {
                    if (!this.props.auth.auth) {
                        return <Redirect push to={"/" + this.props.i18n.language + '/login'} />;
                    }
                    return <IndustryUpdate id={param1} />;
                }
                case 'industry-attribute': {
                    if (!this.props.auth.auth) {
                        return <Redirect push to={"/" + this.props.i18n.language + '/login'} />;
                    }
                    if (!param1) {
                        return <Redirect push to={"/" + this.props.i18n.language + '/industry-management'} />;
                    } else if (!param2) {
                        return <IndustryAttributeUpdate industry_type_id={param1} />;
                    } else {
                        return <IndustryAttributeUpdate industry_type_id={param1} id={param2} />;
                    }
                }

                /*** Tenant ***/
                case 'tenant-management': {
                    if (!this.props.auth.auth) {
                        return <Redirect push to={"/" + this.props.i18n.language + '/login'} />;
                    }
                    if (param1) {
                        return <TenantDetails id={param1} />;
                    } else {
                        return <TenantManagement />;
                    }
                }
                case 'tenant': {
                    if (!this.props.auth.auth) {
                        return <Redirect push to={"/" + this.props.i18n.language + '/login'} />;
                    }
                    return <TenantUpdate id={param1} />;
                }

                default: {
                    return <PageNotFound />;
                }
            }
        } else {
            if (!this.props.auth.auth) {
                return <Redirect push to={"/" + this.props.i18n.language + '/login'} />;
            }
            if (get(this, ['props', 'auth', 'userInfo', 'role']) == 1) {
                return <TenantManagement />;
            }
            return <AuctionManagement />;
        }
    }

    isPublicPage = (currentURL) => {
        return ['login'].includes(currentURL);
    }

    render() {
        const { classes, t } = this.props;
        const { openSnackbar, messageInfo } = this.state;

        let pathname = this.props.route.location.pathname,
            search = this.props.route.location.search,
            urlArray = pathname.split("/"),
            currentURL = urlArray[2],
            params = null;

        if (search !== "") {
            params = querySearch(search);
        }

        const isPublicPage = this.isPublicPage(currentURL);

        const sidebarDrawer = (
            <Sidebar
                currentURL={currentURL}
                toolbarClass={classes.toolbar}
            />);

        const sidebarNav = (
            !isPublicPage &&
            <nav className={classes.drawer}>
                <Hidden smUp implementation="css">
                    <Drawer
                        container={this.props.container}
                        variant="temporary"
                        anchor={'left'}
                        open={this.state.mobileOpen}
                        onClose={this.handleDrawerToggle}
                        classes={{ paper: classes.drawerPaper, }}
                    >
                        {sidebarDrawer}
                    </Drawer>
                </Hidden>
                <Hidden xsDown implementation="css">
                    <Drawer classes={{ paper: classes.drawerPaper }} variant="permanent" open >
                        {sidebarDrawer}
                    </Drawer>
                </Hidden>
            </nav>
        );

        const header = (
            isPublicPage ?
                <PublicHeader />
                : <PrivateHeader handleDrawerToggle={this.handleDrawerToggle} btnMenuClass={classes.menuButton} />
        )

        const footer = currentURL === 'login' && (
            <AppBar position="fixed" className={classes.footer}>
                <div className="footer__container flex-center-item">
                    <div>{t('Common:Footer.description')}</div>
                </div>
            </AppBar>
        );

        return (
            <div className={classes.root}>
                <CssBaseline />
                {header}
                {sidebarNav}
                <main className={classes.content}>
                    <div className={`full-height ${isPublicPage ? 'public-page' : 'private-page'}`}>
                        <div className={classes.toolbar} />
                        {this.getComponent(urlArray, params)}

                        <Snackbar
                            key={messageInfo.key}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={openSnackbar}
                            autoHideDuration={2000}
                            onClose={this.handleCloseSnackbar}
                            onExited={this.handleExited}
                            ContentProps={{
                                'aria-describedby': 'message-id',
                            }}
                        >
                            <MySnackbarContentWrapper
                                variant={messageInfo.variantSnackbar}
                                message={messageInfo.messageSnackbar}
                                onClose={() => this.handleCloseSnackbar(null, 'click')}
                            />
                        </Snackbar>
                    </div>
                </main>
                {footer}
            </div>
        );
    }
}
App.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    route: state.router,
    auth: state.auth,
    messages: state.messages,
});

const mapDispatchToProps = dispatch => ({
    loginP: data => dispatch(login(data)),
    logoutP: data => dispatch(logout(data)),
    setBreadcrumbP: data => dispatch(setBreadcrumb(data)),
    setMessagesP: data => dispatch(setMessages(data))
});

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles, { withTheme: true })(App)));
